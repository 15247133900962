import styled from "styled-components";

const Path = styled.path`
    stroke: #747c90;
    fill:   #747c90;
    stroke-width: 3px;
    transition: all 100ms ease-in-out;
    &:hover {
        stroke: #F28A56;
        fill:   #F28A56;
    }
`;

const Icon = ({ filled, ...props }) => {
    return (
    <svg
        width="13"
        height="13"
        viewBox="0 0 32 32" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            filled={filled}
            {...props}
            d="M26.9247 11.6125C26.8482 11.4305 26.7195 11.2752 26.5549 11.1662C26.3903 11.0572 26.1971 10.9994 25.9997 11H5.99971C5.80228 10.9994 5.60909 11.0572 5.44448 11.1662C5.27987 11.2752 5.15121 11.4305 5.07471 11.6125C5.00266 11.7972 4.98439 11.9985 5.02199 12.1932C5.0596 12.3879 5.15155 12.5679 5.28721 12.7125L15.2872 22.7125C15.4783 22.8973 15.7338 23.0006 15.9997 23.0006C16.2656 23.0006 16.5211 22.8973 16.7122 22.7125L26.7122 12.7125C26.8479 12.5679 26.9398 12.3879 26.9774 12.1932C27.015 11.9985 26.9968 11.7972 26.9247 11.6125Z"    
        />
    </svg>
    );
};

export default Icon;
