const Icon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 2.68164C17.52 2.68164 22 7.16164 22 12.6816C22 18.2016 17.52 22.6816 12 22.6816C6.48 22.6816 2 18.2016 2 12.6816C2 7.16164 6.48 2.68164 12 2.68164ZM6.023 16.0976C7.491 18.2876 9.695 19.6816 12.16 19.6816C14.624 19.6816 16.829 18.2886 18.296 16.0976C16.6317 14.5422 14.4379 13.6784 12.16 13.6816C9.88171 13.6782 7.68751 14.542 6.023 16.0976ZM12 11.6816C12.7956 11.6816 13.5587 11.3656 14.1213 10.803C14.6839 10.2404 15 9.47729 15 8.68164C15 7.88599 14.6839 7.12293 14.1213 6.56032C13.5587 5.99771 12.7956 5.68164 12 5.68164C11.2044 5.68164 10.4413 5.99771 9.87868 6.56032C9.31607 7.12293 9 7.88599 9 8.68164C9 9.47729 9.31607 10.2404 9.87868 10.803C10.4413 11.3656 11.2044 11.6816 12 11.6816Z"
                fill="#F28A56"
            />
        </svg>
    );
};

export default Icon;
